<template>
  <section>
    <b-card
      v-if="statisticsItems"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Dữ liệu tổng quan</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <!-- Updated 1 month ago -->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t(item.subtitle) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
          style="gap: 8px;"
        >
          <div>
            <h4>{{ $t('Quản lý kho hàng') }}</h4>
            <!-- <div>{{ $t('Create combos with attractive offers that will attract more buyers!') }}</div> -->
          </div>
          <!-- <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="/combo/add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button> -->
        </div>

        <div class="filter-tab w-100">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }}
          </div>
        </div>
      </b-card-header>
      <b-card-body>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
          <b-form-group style="width: 450px;">
            <div class="d-flex align-items-center mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Tìm kiếm theo mã sản phẩm, tên sản phẩm, barcode"
                />
              </b-input-group>
            </div>
          </b-form-group>
          <!-- search theo danh mục -->
          <b-form-group style="width: 350px;">
            <div class="d-flex align-items-center mr-1">
              <!-- <label class="mr-1">{{ $t('Discount promotion period') }}</label> -->
              <treeselect
                v-model="model.category_id"
                :multiple="false"
                :clearable="true"
                :searchable="true"
                :open-on-click="true"
                :clear-on-select="true"
                :show-count="true"
                :options="cateProductOptions"
                :limit="5"
                :max-height="400"
                :placeholder="$t('Danh mục')"
                @input="handlerChangeCategory"
              />
            </div>
          </b-form-group>

          <!-- search theo thoi gian ton kho -->
          <b-form-group style="width: 350px;">
            <div class="d-flex align-items-center mr-1">
              <!-- <label class="mr-1">{{ $t('Discount promotion period') }}</label> -->
              <treeselect
                v-model="model.time"
                :multiple="false"
                :clearable="true"
                :searchable="true"
                :open-on-click="true"
                :clear-on-select="true"
                :show-count="true"
                :options="timeOptions"
                :limit="5"
                :max-height="400"
                :placeholder="$t('Thời gian tồn kho')"
              />
            </div>
          </b-form-group>

          <!-- search theo trang thai -->
          <b-form-group style="width: 350px;">
            <div class="d-flex align-items-center">
              <!-- <label class="mr-1">{{ $t('Discount promotion period') }}</label> -->
              <treeselect
                v-model="model.status"
                :multiple="false"
                :clearable="true"
                :searchable="true"
                :open-on-click="true"
                :clear-on-select="true"
                :show-count="true"
                :options="statusOptions"
                :limit="5"
                :max-height="400"
                :placeholder="$t('Status')"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          ref="promotion-products"
          style-class="vgt-table"
          class="custom-good-table"
          :columns="fields"
          :rows="items"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Avatar + Name -->
            <span
              v-if="props.column.field === 'name'"
              style="max-width: 240px;"
            >
              <div class="d-flex align-items-center">
                <b-avatar
                  rounded
                  size="48"
                  variant="light-company"
                  class="mr-1"
                  :src="props.row.avatar"
                />
                <div>
                  <div class="font-weight-bolder">
                    {{ props.row.name }}
                  </div>
                  <div class="font-small-3">
                    {{ props.row.sku }}
                  </div>
                </div>
              </div>
            </span>

            <span
              v-else-if="props.column.field === 'barcode'"
              style="max-width: 140px;"
            >
              {{ props.row.barcode }}
              <feather-icon
                class="cursor-pointer ml-1"
                icon="CopyIcon"
                @click="doCopy(props.row.barcode)"
              />
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                class="d-block"
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status_label }}
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'stock'"
              class="d-block"
              style="max-width: 140px;"
            >{{ (props.row.stock) }}</span>

            <span
              v-else-if="props.column.field === 'keep_stock'"
              class="d-block"
              style="max-width: 140px;"
            >{{ (props.row.keep_stock) }}</span>

            <span
              v-else-if="props.column.field === 'availability'"
              class="d-block"
              style="max-width: 140px;"
            >{{ (props.row.availability) }}</span>

            <span
              v-else-if="props.column.field === 'sold'"
              class="d-block"
              style="max-width: 140px;"
            >{{ (props.row.sold) }}</span>

            <span
              v-else-if="props.column.field === 'price'"
              class="d-block"
              style="max-width: 140px;"
            >{{ unitFormatOriginal(props.row.price) }}</span>

            <span
              v-else-if="props.column.field === 'cost_price'"
              class="d-block"
              style="max-width: 140px;"
            >{{ unitFormatOriginal(props.row.cost_price) }}</span>

            <span
              v-else-if="props.column.field === 'total'"
              class="d-block"
              style="max-width: 140px;"
            >{{ unitFormatOriginal(props.row.total) }}</span>

            <!-- Column: Actions -->
            <span v-else-if="props.column.field === 'actions'">
              <router-link :to="`/combo/edit/${props.row.id}`">{{ $t('Detail') }}</router-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pageOptions"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ totalRows }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="currentPageOriginal"
                  :total-rows="totalRows"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  // BButton,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BBadge,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    // BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BBadge,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    VueGoodTable,
    Treeselect,
  },
  mixins: [general],
  data() {
    return {
      modelQuery: {
        category_id: null,
        name: null,
        quantity: null,
        updated_at: null,
        table_sort: null,
        tab: 0,
      },
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '2300',
          subtitle: 'Số lượng tồn kho',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'light-info',
          title: '8.549M',
          subtitle: 'Tổng giá bán',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'light-danger',
          title: '1.423M',
          subtitle: 'Tổng giá trị',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '7.126M',
          subtitle: 'Lợi nhuận',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPageOriginal: 1,
      perPage: 20,
      pageLength: 20,
      searchTerm: '',
      // Fields header table
      fields: [
        {
          field: 'name',
          label: 'Product',
          width: '600px',
          sortable: false,
        },
        {
          field: 'barcode',
          label: 'Barcode',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'stock',
          label: 'Tồn kho',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        // {
        //   field: 'keep_stock',
        //   label: 'Đặt',
        //   type: 'number',
        //   tdClass: 'text-center',
        //   sortable: false,
        // },
        {
          field: 'availability',
          label: 'Khả dụng',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'price',
          label: 'Giá bán',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'cost_price',
          label: 'Giá gốc',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'total',
          label: 'Tổng',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
      filterSelected: 0,
      filterTabs: [
        {
          id: 0,
          label: 'All',
        },
        {
          id: 1,
          label: 'Mới nhập 7 ngày',
        },
        {
          id: 2,
          label: 'Sắp hết hàng <= 10',
        },
        {
          id: 3,
          label: 'Hết hàng 0',
        },
      ],
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      cateProductOptions: [],
      statusOptions: [
        {
          id: 1,
          label: 'Đang bán',
        },
        {
          id: 0,
          label: 'Ngưng bán',
        },
      ],
      timeOptions: [
        {
          id: 1,
          label: '1 tháng',
        },
        {
          id: 2,
          label: '2 tháng',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-success',
        upComing     : 'light-danger',
        finished     : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 0) return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  watch: {
    currentPageOriginal() {
      this.loadList()
    },
    pageLength() {
      this.loadList()
    },
    filterSelected() {
      this.$router.replace({
        query: { tab: this.filterSelected },
      }).catch(() => {})
      this.loadList()
    },
  },
  created() {
    this.loadList()
    this.loadListCateProdyct()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let strParams = ''
        if (this.$route.query.tab) {
          if (Number(this.$route.query.tab) === 1) strParams += `&updated_at=>=,${this.getCurrentDate(false, '-', 7)}`
          if (Number(this.$route.query.tab) === 2) strParams += '&quantity=<=,10}'
          if (Number(this.$route.query.tab) === 3) strParams += '&quantity=<=0,0}'
        }
        Object.keys(this.modelQuery).map(isKey => {
          if (this.modelQuery[isKey]) {
            strParams += `&${isKey}=${this.modelQuery[isKey]}`
          }
          return true
        })

        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/storages_warehouses?site_id=${siteId}&sort[]=created_at,desc${strParams}&limit=${this.pageLength}&page=${this.currentPageOriginal}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = []
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total

            // const array = []
            // res.data.data.items.map(val => {
            //   if (!array.find(attName => attName.name === val.name)) {
            //     array.push(val)
            //   }
            // })
            res.data.data.items.map(val => {
              // const findData = res.data.data.items.filter(
              //   ({ name }) => name === val.name,
              // )
              const nameAttribute = (val.warehouse.name_attribute)
              const str = []
              nameAttribute.map(val2 => {
                str.push(val2.value)
                return true
              })

              const item = {
                id: val.id,
                product_id: val.warehouse.product.id,
                avatar: val.warehouse.avatar,
                name: `${val.warehouse.product.name} - ${str.join('-')}`,
                warehouse_id: val.warehouse.id,
                sku: val.warehouse.sku,
                price: val.warehouse.price,
                cost_price: val.warehouse.cost_price,
                stock: val.quantity,
                keep_stock: val.keep_stock,
                availability: val.quantity - val.keep_stock,
                total: val.quantity * val.warehouse.price,
                is_active: 1,
                is_warehouse: 1,
                updated_at: val.updated_at,
                updated_at_utc: val.updated_at,
                barcode: val.warehouse.barcode,
              }
              // let sumStock = 0
              // let sumSold = 0
              // findData.map(y => {
              //   sumStock += y.stock
              //   sumSold += y.sold
              //   item.stock = sumStock
              //   item.sold = sumSold
              // })
              this.items.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    getAttributes(data) {
      const temp = JSON.parse(data)
      return ` ${temp[0] ? temp[0].value : ''}${temp[1] ? ` / ${temp[1].value}` : ''}${temp[2] ? ` / ${temp[2].value}` : ''}`
    },
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    async loadListCateProdyct() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.slug,
              label: x.name,
              slug: x.slug,
              idx: x.id,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.slug,
                  label: y.name,
                  slug: y.slug,
                  idx: y.id,
                }
                item.children.push(i)
              })
            }
            this.cateProductOptions.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
